import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/system";

const Wrapper = styled(Box)(() => ({
    width: "100vw",
    height: "100vh",
    // background: "linear-gradient(to right, #119e8d, #064257, #021f44)",
    // backgroundColor: "#282c34",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
}));


interface IBaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<IBaseLayoutProps> = () => {
    return (
        <Wrapper>
            <Outlet />
        </Wrapper>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node,
};

export default BaseLayout;
