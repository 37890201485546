import { createBrowserRouter } from "react-router-dom";
import BaseLayout from "./components/base-layout";
import SuspenseLoader from "./components/loader/suspense-loader";
import { lazy, Suspense } from "react";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const PageNotFound = Loader(lazy(() => import("./components/page-not-found")));
const HomePage = Loader(lazy(() => import("./contents/homepage")));
const DownloadAndShare = Loader(
  lazy(() => import("./contents/homepage/download-share"))
);
const Player = Loader(lazy(() => import("./contents/player")));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/player", element: <Player /> },
      { path: "/:id", element: <DownloadAndShare /> },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
    errorElement: <PageNotFound />,
  },
]);
